<template>
  <div class="modal-recommender-wrapper">
    <esmp-modal
      v-model="showModal"
      title="Добавление рекомендателя"
      :width="600"
      ok-text="Да, добавить"
      cancel-text="Нет, я передумал"
      @on-ok="okHandler"
      @on-hidden="closeHandler"
      :disabled-ok-button="!hasUser"
    >
      <hm-search
        v-model="userList"
        class="customer-user__search"
        placeholder="Поиск сотрудников"
      />
    </esmp-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Hub from '@/plugins/event-hub';
import HmSearch from '../../hm-form/controls/HmSearch.vue';

export default {
  name: 'ModalRecommender',
  components: { HmSearch },
  props: {
    visible: {
      type: Boolean,
    },
    sourceSystem: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userList: [],
    };
  },
  computed: {
    ticketId() {
      return this.$route.params.id;
    },
    hasUser() {
      return this.userList && this.userList.length;
    },
    showModal: {
      get() {
        return this.visible;
      },
      set(newValue) {
        this.$emit('hide-modal', { modalName: this.$options.name, newValue });
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    okHandler() {
      this.setLoading({ key: 'app', value: true });
      this.$API.approval.addRecommender(this.ticketId, {
        approvalList: this.userList.map((el) => el.login),
      }, {
        source: this.sourceSystem,
      }).then(() => {
        Hub.$emit('update-ticket');
      }).finally(() => this.setLoading({ key: 'app', value: false }));
    },
    closeHandler() {
      this.userList = [];
      this.$emit('hide-modal', { modalName: this.$options.name, newValue: false });
    },
  },
};
</script>
